









































































import { Component, PropSync, Vue } from 'vue-property-decorator';

import FlagshipProfilingSowosPocketViewModel
  from '@/vue-app/view-models/components/flagship/flagship-profiling/flagship-profiling-sowos-pocket-view-model';

import FlagshipProfilingExitPoll
  from '@/vue-app/components/flagship/flagship-profiling/FlagshipProfilingExitPoll.vue';

@Component({
  name: 'FlagshipProfilingSowosPocket',
  components: {
    FlagshipProfilingExitPoll,
    ContractSavings: () => import('@/vue-app/components/ContractSavings.vue'),
  },
})
export default class FlagshipProfilingSowosPocket extends Vue {
  @PropSync('isOpen', { type: Boolean, required: true })
  synced_is_open!: boolean;

  flagship_pocket_view_model = Vue.observable(
    new FlagshipProfilingSowosPocketViewModel(),
  );

  showContractSavingsModal() {
    this.synced_is_open = false;
    this.flagship_pocket_view_model.showContractSavingsModal();
  }

  closeModal() {
    this.synced_is_open = false;
    this.flagship_pocket_view_model.showPollModal();
  }
}
